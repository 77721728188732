import React from "react";
import { Size } from "../types/Size";
import { SortBy } from "../types/SortBy";
import { typeToString } from "../extensions/helpers";

interface FilterSortProps {
  size: Size;
  sort: SortBy;
  setSize: (size: Size) => void;
  setSort: (sort: SortBy) => void;
  productCount: number;
}

const FilterSort: React.FC<FilterSortProps> = ({
  size,
  sort,
  setSize,
  setSort,
  productCount,
}) => {
  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFilter = Size[e.target.value as keyof typeof Size];
    setSize(selectedFilter);
  };

  const handleSortChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSort = SortBy[e.target.value as keyof typeof SortBy];
    setSort(selectedSort);
  };

  return (
    <div className="flex flex-wrap justify-between items-center mb-4">
      <div className="flex items-center">
        <span className="font-bold mr-2">Filter:</span>
        <select
          id="size"
          name="size"
          className="select"
          value={size}
          onChange={handleFilterChange}
        >
          {Object.keys(Size).map((size) => {
            return (
              <option key={size} value={Size[size as keyof typeof Size]}>
                {typeToString(size)}
              </option>
            );
          })}
        </select>
      </div>

      <div className="flex items-center gap-4">
        <div className="flex flex-row">
          <span className="mr-2 font-bold whitespace-nowrap">
            Sort{" "}
            <label className="font-extrabold underline">{productCount}</label>{" "}
            products:
          </span>
          <select
            id="sort"
            name="sort"
            className="select"
            value={sort}
            onChange={handleSortChange}
          >
            {Object.keys(SortBy).map((sort) => {
              return (
                <option key={sort} value={SortBy[sort as keyof typeof SortBy]}>
                  {typeToString(sort)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilterSort;
