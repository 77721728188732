import { useEffect } from "react";
import Carousel from "../components/Carousel";
import ClassicProductList from "../components/ClassicProductList";
import { useSelectedLocation } from "../contexts/SelectedLocationContext";
import { HomePageCarouselImages } from "../data/CarouselImages";
import HomePageSection from "../components/HomePageSection";
import MediaFooter from "../components/MediaFooter";
// import ThemeCard from "../components/ThemeCard";
// import { Theme } from "../types/Theme";
// import { getCurrentYear, getSeason } from "../extensions/helpers";

const HomePage = () => {
  const {
    setCategory,
    setProductLine,
    setProduct,
    setTheme,
    setSeason,
    setYear,
  } = useSelectedLocation();
  useEffect(() => {
    setCategory("Home");
    setProductLine(null);
    setProductLine(null);
    setProduct(null);
    setTheme(null);
    setSeason(null);
    setYear(null);
  }, [setCategory, setProduct, setProductLine, setSeason, setTheme, setYear]);

  return (
    <div className="homepage-container">
      <HomePageSection className="carousel bg-[#f0e4d7]">
        <Carousel images={HomePageCarouselImages} interval={7500} />
      </HomePageSection>

      <HomePageSection className="flex flex-col">
        <ClassicProductList />
      </HomePageSection>

      <HomePageSection className="homepage-container-full-image">
        <img
          src={"/images/main-design-about.png"}
          alt={`Main Design About`}
          className="w-full h-full"
        />
      </HomePageSection>

      <HomePageSection className="homepage-container-full-image">
        <img
          src={"/images/main-design-murica.png"}
          alt={`Main Design Murica`}
          className="w-full h-full"
        />
      </HomePageSection>

      <HomePageSection>
        <MediaFooter />
      </HomePageSection>
    </div>
  );
};

export default HomePage;
