import React from "react";

type HomePageSectionProps = {
  className?: string;
  children: React.ReactNode;
};

const HomePageSection = ({ className, children }: HomePageSectionProps) => {
  return <div className={`homepage-section ${className}`}>{children}</div>;
};

export default HomePageSection;
