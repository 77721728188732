// src/components/HamburgerMenu.tsx

import React from "react";
import TabDropdownList from "./TabDropdownList";
import { useHamburgerMenu } from "../contexts/HamburgerMenuContext";
import CartButton from "./CartButton";

interface HamburgerMenuProps {}

const HamburgerMenu: React.FC<HamburgerMenuProps> = () => {
  const { isHamburgerMenuOpen } = useHamburgerMenu();

  return (
    <div
      className={`hamburger-menu ${
        isHamburgerMenuOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {/* Navigation Tabs */}
      <nav>
        <TabDropdownList />
      </nav>

      {/* Cart Button */}
      <CartButton hasLabel className="self-center" />
    </div>
  );
};

export default HamburgerMenu;
