// src/components/ProductCard.tsx

import React from "react";
import { Product } from "../types/Product";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import { getLink } from "../extensions/helpers";

interface ProductCardProps {
  product: Product;
  onClick?: () => void;
  isSmallVersion?: boolean;
  forceReload?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  onClick,
  isSmallVersion = false,
  forceReload = false,
}) => {
  const navigate = useNavigate();

  const handleClickCard = () => {
    if (onClick != null) {
      onClick();
    }
    navigate(
      getLink({
        category: product.productCategories[0],
        productLine: product.productLine,
        product: product.url,
      }),
      {
        state: { selectedProduct: product },
        replace: true,
      }
    );
    if (forceReload) {
      window.location.reload();
    }
  };

  return (
    <Card className="hover:cursor-pointer" onClick={handleClickCard}>
      <img
        src={product.images[0]?.imagePaths[0]}
        alt={product.name}
        className={`product-card-image ${
          !isSmallVersion ? "h-96 rounded-b-none" : "rounded-b-lg"
        }`}
      />
      {!isSmallVersion && (
        <div className="py-1">
          <h3 className="text-left font-semibold">{product.name}</h3>
          <p className="text-left text-sm">${product.price.toFixed(2)}</p>
        </div>
      )}
    </Card>
  );
};

export default ProductCard;
