// src/components/ProductList.tsx

import React from "react";
import ProductCard from "./ProductCard";
import { Product } from "../types/Product";

interface ProductListProps {
  products: Product[];
  onClickProduct?: () => void;
  isSmallVersion?: boolean;
  forceReload?: boolean;
}

const ProductList: React.FC<ProductListProps> = ({
  products,
  onClickProduct,
  isSmallVersion = false,
  forceReload = false,
}) => {
  return (
    <div className="homepage-productlist-boxes">
      {products.map((product: Product, i: number) => (
        <ProductCard
          key={i}
          product={product}
          onClick={onClickProduct}
          isSmallVersion={isSmallVersion}
          forceReload={forceReload}
        />
      ))}
    </div>
  );
};

export default ProductList;
