type CardProps = {
  title?: string | React.ReactNode;
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
};

const Card = ({ title, onClick, className, children }: CardProps) => {
  return (
    <div className={`card ${className}`} onClick={onClick}>
      {title && <h2>{title}</h2>}
      {children}
    </div>
  );
};

export default Card;
