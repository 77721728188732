import debounce from "lodash.debounce";
import React, { useCallback, useState } from "react";
import { useSearch } from "../contexts/SearchContext";
import SearchButton from "./SearchButton";

const SearchInput = () => {
  const { searchQuery, setSearchQuery } = useSearch();
  const [searchInput, setSearchInput] = useState<string>(searchQuery);

  // Create a debounced version of the onSearch function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
    }, 300),
    [setSearchQuery]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchInput(query);
    debouncedSearch(query);
  };

  const clearSearch = () => {
    setSearchInput("");
    setSearchQuery("");
  };

  return (
    <div className="search-bar">
      {/* SVG Magnifying Glass Icon */}
      <SearchButton className="search-menu-search-button" />
      <input
        type="text"
        value={searchInput}
        onChange={handleSearchChange}
        className="search-input"
        placeholder="Search..."
        autoFocus
      />
      {searchQuery && (
        <button onClick={clearSearch} className="clear-button">
          &times; {/* This is the "X" symbol */}
        </button>
      )}
    </div>
  );
};

export default SearchInput;
