import React, { useState } from "react";
import { useCart } from "../contexts/CartContext";
import Counter from "./Counter";
import { typeToString, sizeToString } from "../extensions/helpers";
import { PaymentResponse } from "../types/PaymentResponse";
import PaymentForm from "./PaymentForm";
import MenuCloseButton from "./MenuCloseButton";

interface CartProps {}

const Cart: React.FC<CartProps> = () => {
  const { isCartOpen, setIsCartOpen, cart, addToCart, removeFromCart } =
    useCart();
  const [paymentResponse, setPaymentResponse] = useState<PaymentResponse>(null);

  const totalPrice = cart.reduce(
    (sum, item) => sum + item.product.price * item.quantity,
    0
  );

  return (
    <div
      className={`cart ${isCartOpen ? "translate-x-0" : "translate-x-full"}`}
    >
      <MenuCloseButton onClick={() => setIsCartOpen(!isCartOpen)} />

      <div>
        {cart.length === 0 ? (
          <p className="absolute w-full top-1/2 text-center text-2xl">
            YOUR CART IS EMPTY
          </p>
        ) : (
          <ul className="mt-14 space-y-4 border-t border-gray-400">
            {cart.map((item, index) => (
              <li
                key={item.product.id}
                className={`flex items-center justify-between pt-4 ${
                  index > 0 && "border-gray-700 border-t"
                }`}
              >
                <div className="flex items-center px-6">
                  <img
                    src={
                      item.product.images?.find((i) => i.color === item.color)
                        ?.imagePaths[0]
                    }
                    alt={item.product.name}
                    className="w-16 h-16 object-cover mr-4"
                  />
                  <div>
                    <h3 className="text-lg font-semibold">
                      {item.product.name}
                    </h3>
                    <p className="text-sm">Size: {sizeToString(item.size)}</p>
                    <p className="text-sm">Color: {typeToString(item.color)}</p>
                    <p className="text-sm">
                      Price: ${item.product.price.toFixed(2)}
                    </p>
                    <Counter
                      quantity={item.quantity}
                      add={() => addToCart(item)}
                      remove={() => removeFromCart(item)}
                    />
                  </div>
                </div>
                <button
                  onClick={() => removeFromCart(item, false)}
                  className="cart-remove-button text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>

      <PaymentForm
        amount={totalPrice}
        paymentResponse={paymentResponse}
        setPaymentResponse={setPaymentResponse}
      />
    </div>
  );
};

export default Cart;
