import { CarouselImage } from "../types/CarouselImage";
import { ProductCategory } from "../types/ProductCategory";
import { Theme } from "../types/Theme";

export const HomePageCarouselImages: CarouselImage[] = [
  {
    imagePath: "/images/main-design-carousel.png",
    className: "carousel-image-full",
  },
  {
    imagePath: "/images/logo-title-bottom-narrow.png",
    category: ProductCategory.Theme,
    theme: Theme.Classic,
  },
  { imagePath: "/images/products/sweater-logo-brown-2.png" },
  { imagePath: "/images/products/polo-logo-brown-2.png" },
  { imagePath: "/images/products/tanktop-arsenal-green-2.png" },
  { imagePath: "/images/products/tshirt-speaksoftly-brown-2.png" },
];
