import Fuse, { FuseSearchOptions } from "fuse.js";
import { Products } from "../data/Products";
import { Product } from "../types/Product";
import { ProductCategory } from "../types/ProductCategory";
import { ProductLine } from "../types/ProductLine";
import { Size } from "../types/Size";
import { Color } from "../types/Color";
import { Theme } from "../types/Theme";
import { Season } from "../types/Season";
import { SortBy } from "../types/SortBy";

export const capitalizeFirstLetter = (string?: string) => {
  return string == null
    ? null
    : string.charAt(0).toUpperCase() + string.slice(1);
};

export const filterProducts = (
  query: string,
  category?: ProductCategory,
  productLine?: ProductLine,
  theme?: Theme,
  season?: Season,
  year?: number,
  size?: Size,
  sortBy?: SortBy,
  limit?: number
): Product[] => {
  const isTheme = category === ProductCategory.Theme && !!theme;

  // Filter products by page first
  let filtered = Products;
  if (isTheme) {
    filtered = filtered.filter((p) => {
      return !!p.themes.find(
        (t) =>
          t.theme === theme &&
          (!!season ? season === t.season : true) &&
          (!!year ? t.years.includes(year) : true)
      );
    });
  } else {
    filtered = filtered.filter((p) =>
      !!productLine ? p.productLine === productLine : p
    );
  }

  // Filter and sort products by filter and sort bar
  if (!!size) {
    filtered = filtered.filter((p) => p.sizes.includes(size));
  }

  // Apply fuzzy search on productLine-filtered products
  const fuse = new Fuse(filtered, {
    keys: [
      { name: "name", weight: 0.9 },
      { name: "description", weight: 0.1 },
    ],
    // includeScore: true,
    threshold: 0.3, // Stricter matching
    distance: 50, // Require closer matches
    useExtendedSearch: true, // Allow exact queries
    ignoreLocation: true, // Match regardless of position
    shouldSort: sortBy === SortBy.Relevance, // Sort by relevance
  });

  const result = fuse.search(query);
  const searchFiltered = result.map((r) => r.item);

  // Combine both filters
  const combinedFiltered = filtered.filter((p) =>
    query.length > 0 ? searchFiltered.includes(p) : p
  );

  // Sort
  switch (sortBy) {
    case SortBy.Alphabetically_AZ:
      return combinedFiltered.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: "base" })
      );
    case SortBy.Alphabetically_ZA:
      return combinedFiltered.sort((a, b) =>
        b.name.localeCompare(a.name, undefined, { sensitivity: "base" })
      );
    case SortBy.Date_Old_to_New:
      return combinedFiltered.sort(
        (a, b) => a.dateOnMarket.getTime() - b.dateOnMarket.getTime()
      );
    case SortBy.Date_New_to_Old:
      return combinedFiltered.sort(
        (a, b) => b.dateOnMarket.getTime() - a.dateOnMarket.getTime()
      );
    case SortBy.Price_Low_to_High:
      return combinedFiltered.sort((a, b) => a.price - b.price);
    case SortBy.Price_High_to_Low:
      return combinedFiltered.sort((a, b) => b.price - a.price);
    case SortBy.Relevance: // This is handled by Fuse above
    case SortBy.Featured:
    case SortBy.Best_Sellers:
    default:
      return combinedFiltered;
  }
};

export const filterGlobalTypeSearch = <T>(query: string, enumType: T) => {
  const values: { name: string }[] = Object.values(enumType).map((pc) => ({
    name: pc.toString(),
  }));

  // Apply fuzzy search on productLine-filtered products
  const fuse = new Fuse(values, {
    keys: [{ name: "name", weight: 1.0 }],
    threshold: 0.3, // Stricter matching
    distance: 50, // Require closer matches
    useExtendedSearch: true, // Allow exact queries
    ignoreLocation: true, // Match regardless of position
    shouldSort: true, // Sort by relevance
  });

  const options: FuseSearchOptions = { limit: 3 };
  const result = fuse.search(query, options);
  return result.map((r) => r.item.name);
};

export const parameterToCategoryType = (category: string) => {
  return ProductCategory[
    capitalizeFirstLetter(category) as keyof typeof ProductCategory
  ];
};

export const parameterToProductLineType = (productLine: string) => {
  return ProductLine[
    capitalizeFirstLetter(productLine) as keyof typeof ProductLine
  ];
};

export const parameterToThemeType = (theme: string) => {
  return Theme[capitalizeFirstLetter(theme) as keyof typeof Theme];
};

export const parameterToSeasonType = (season: string) => {
  return Season[capitalizeFirstLetter(season) as keyof typeof Season];
};

export const parameterToYear = (year: string): number => {
  return +year;
};

export const countOccurrenceInString = (
  str: string,
  separator: string
): number => {
  return str.split(separator).length - 1;
};

export const sizeToString = (size: Size) => {
  const sizeString = size.toString();
  const countXs = countOccurrenceInString(sizeString, "X");
  return countXs > 1
    ? countXs.toString() + "X" + sizeString.replaceAll("X", "")
    : sizeString;
};

export const typeToString = (
  typeString: Color | Theme | Season | string | number
) => {
  return typeString?.toString().replaceAll("_", " ");
};

export const typeToUrl = (
  typeString:
    | ProductLine
    | ProductCategory
    | Color
    | Theme
    | Season
    | string
    | number
) => {
  return typeString.toString().replaceAll("_", "").toLowerCase();
};

export const addMonths = (date: Date, numMonths: number) => {
  const newDate = new Date(date);
  // If next season is specified, add 3 months
  newDate.setMonth(newDate.getMonth() + numMonths);

  // Handle year rollover if necessary
  if (newDate.getDate() !== date.getDate()) {
    newDate.setDate(0); // Set to the last day of the previous month
  }

  return newDate;
};

export const getSeason = (next?: boolean): Season => {
  // TODO: Enhance to be more granular

  const currentDate = new Date();
  const newDate = next ? addMonths(currentDate, 1) : currentDate;

  const month = newDate.getMonth(); // 0 = January, 11 = December

  switch (month) {
    case 11:
    case 0:
    case 1:
      return Season.Winter;
    case 2:
    case 3:
    case 4:
      return Season.Spring;
    case 5:
    case 6:
    case 7:
      return Season.Summer;
    case 8:
    case 9:
    case 10:
      return Season.Fall;
    default:
      throw new Error("Invalid month");
  }
};

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

export const getCategoryTitle = (
  theme: Theme,
  currentSeason?: Season,
  currentYear?: number
) => {
  switch (theme) {
    case Theme.Season:
      return `${typeToString(currentSeason)} ${currentYear}`;
    case Theme.New:
      return `${typeToString(theme) + " Release"}`;
    default:
      return `${typeToString(theme)}s`;
  }
};

type GetLinkProps = {
  category: ProductCategory;
  productLine?: ProductLine;
  product?: string;
  theme?: Theme;
  season?: Season;
  year?: number;
};
export const getLink = ({
  category,
  productLine,
  product,
  theme,
  season,
  year,
}: GetLinkProps): string => {
  let link = `/categories/${typeToUrl(category)}`;
  switch (category) {
    case ProductCategory.Theme: {
      // Defaults if Theme is null but Category is Theme
      if (!theme) {
        theme ??= Theme.Season;
        season ??= getSeason();
        year ??= getCurrentYear();
      }

      link += `/${typeToUrl(theme)}`;

      switch (theme) {
        case Theme.Season: {
          if (!!season) {
            link += `/seasons/${typeToUrl(season)}`;
          }

          if (!!year) {
            link += `/year/${typeToUrl(year)}`;
          }
        }
      }

      return link;
    }
    case ProductCategory.Apparel:
    case ProductCategory.Gear:
    case ProductCategory.Hats: {
      if (!!productLine) {
        link += `/productlines/${typeToUrl(productLine)}`;
      }

      if (!!product) {
        link += `/products/${typeToUrl(product)}`;
      }

      return link;
    }
    case ProductCategory.About:
      return "/about";
    case ProductCategory.Home:
    default:
      return "/home";
  }
};

type GetThemeImageNameProps = {
  category: ProductCategory;
  theme?: Theme;
  season?: Season;
  year?: number;
};
export const getThemeImageName = ({
  category,
  theme,
  season,
  year,
}: GetThemeImageNameProps): string => {
  let imageName = typeToUrl(category);

  // Theme
  if (!!theme) {
    imageName += "-" + typeToUrl(theme);
  }
  if (!!season) {
    imageName += "-" + typeToUrl(season);
  }
  // if (!!year) {
  //   imageName += "-" + typeToUrl(year);
  // }

  return imageName;
};
