import React from "react";

type SearchButtonProps = {
  label?: string;
  onClick?: () => void;
  hideIcon?: boolean;
  className?: string; // Optional custom className for styling
};

const SearchButton: React.FC<SearchButtonProps> = ({
  label,
  onClick,
  hideIcon = false,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center gap-2 p-1.5 transition duration-200 ${className}`}
      aria-label="SearchButton"
    >
      {!hideIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <line x1="21" y1="21" x2="16.65" y2="16.65" />
        </svg>
      )}
      {label}
    </button>
  );
};

export default SearchButton;
