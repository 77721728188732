import { ProductCategory } from "../types/ProductCategory";
import { ProductLine } from "../types/ProductLine";
import TabDropdown from "./TabDropdown";

const TabDropdownList = () => {
  // const currentYear = getCurrentYear();
  return (
    <>
      <TabDropdown category={ProductCategory.Home} productLines={[]} />

      {/* TODO: Bryce: Themes for the future - not now */}
      {/* <TabDropdown
        category={ProductCategory.Theme}
        productLines={[]}
        theme={Theme.New}
        year={currentYear}
      />
      <TabDropdown
        category={ProductCategory.Theme}
        productLines={[]}
        theme={Theme.Season}
        season={getSeason(true)}
        year={currentYear}
      /> */}

      <TabDropdown
        category={ProductCategory.Apparel}
        productLines={[
          ProductLine.Sweaters,
          // ProductLine.Hoodies,
          ProductLine.Polos,
          // ProductLine.Quarterzips,
          ProductLine.Tshirts,
        ]}
      />
      {/* <TabDropdown
        category={ProductCategory.Hats}
        productLines={[
          ProductLine.Surfhats,
          ProductLine.Ropehats,
          ProductLine.Buckethats,
        ]}
      /> */}
      <TabDropdown category={ProductCategory.About} productLines={[]} />
    </>
  );
};

export default TabDropdownList;
