// src/components/Header.tsx

import React from "react";
import { Link } from "react-router-dom";
import TabDropdownList from "./TabDropdownList";
import { useHamburgerMenu } from "../contexts/HamburgerMenuContext";
import SearchButton from "./SearchButton";
import CartButton from "./CartButton";
import { useSearch } from "../contexts/SearchContext";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { setIsHamburgerMenuOpen } = useHamburgerMenu();
  const { isSearchMenuOpen, setIsSearchMenuOpen } = useSearch();

  const handleOnSearchClick = () => {
    setIsSearchMenuOpen(!isSearchMenuOpen);
  };

  return (
    <header className="header">
      {/* <!-- Hamburger Menu --> */}
      <button
        id="menuButton"
        className="md:hidden lg:hidden xl:hidden"
        onClick={() => setIsHamburgerMenuOpen(true)}
      >
        <svg
          className="w-8 h-8"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>

      <div className="flex gap-8">
        {/* Logo */}
        <Link to="/home" className="flex gap-1 justify-center align-middle">
          {/* <div className="header-logo-background"> */}
          <img
            src="/images/logo-title-main-name-sm.png"
            alt="Logo Overlay"
            className="header-logo-foreground"
          />
          {/* </div> */}
        </Link>

        {/* Navigation Tabs */}
        <nav className="hidden md:flex lg:flex xl:flex space-x-3 px-12">
          <TabDropdownList />
        </nav>
      </div>

      {/* Search and Cart Buttons */}
      <div className="flex flex-row gap-4">
        <SearchButton onClick={handleOnSearchClick} />
        <CartButton />
      </div>
    </header>
  );
};

export default Header;
