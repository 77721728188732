import React from "react";
import { Link } from "react-router-dom";
import { getLink } from "../extensions/helpers";
import { ProductCategory } from "../types/ProductCategory";

const Footer = () => {
  return (
    <footer className="p-8 w-full merriweather-text">
      {/* TODO: Add md:grid-cols-2 and Remove place-items-center - v2 when have infrastructure behind mailing list  */}
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-1 gap-48 place-items-center">
        {/* Mailing List Section */}
        {/* <div>
          <h2 className="font-bold text-lg mb-2 px-2">
            Are you in? Join our mailing list
          </h2>
          <p className="mb-4 px-2">
            for exclusive offers, new arrivals and more.
          </p>
          <form className="email-bar">
            <label htmlFor="email" className="sr-only">
              Email Address
            </label>
            <input
              id="email"
              type="email"
              placeholder="Email Address"
              className="email-input"
            />
            <button type="submit" className="enter-right-arrow-button mt-2">
              👉
            </button>
          </form>
        </div> */}

        {/* Help Section */}
        <div>
          <h2 className="font-bold text-xl mb-2 text-center">Get Help</h2>
          <ul className="space-y-2">
            <li>
              <Link
                className="homepage-section-link"
                to={getLink({
                  category: ProductCategory.About,
                })}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                className="homepage-section-link"
                to={getLink({
                  category: ProductCategory.About,
                })}
              >
                Returns and Exchanges
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {/* Social Media Icons */}
      <div className="flex justify-center mt-8 space-x-4 text-[#4a2c2a]">
        <a
          href="https://facebook.com"
          aria-label="Facebook"
          className="hover:text-[#4a2c2a]"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          href="https://twitter.com"
          aria-label="Twitter"
          className="hover:text-[#4a2c2a]"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          href="https://www.instagram.com/americanaandcompany/profilecard/?igsh=d2h3ZXZ3YWVzMWE4"
          aria-label="Instagram"
          className="hover:text-[#4a2c2a]"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://www.tiktok.com/@americanacompany?_t=8s8ybVXt2MQ&_r=1"
          aria-label="TikTok"
          className="hover:text-[#4a2c2a]"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-tiktok"></i>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
