import React from "react";
import { useCart } from "../contexts/CartContext";
import { useHamburgerMenu } from "../contexts/HamburgerMenuContext";

type CartButtonProps = {
  hasLabel?: boolean;
  className?: string; // Optional custom className for styling
};

const CartButton: React.FC<CartButtonProps> = ({ hasLabel, className }) => {
  const { isCartOpen, setIsCartOpen } = useCart();
  const { setIsHamburgerMenuOpen } = useHamburgerMenu();

  const handleClick = () => {
    setIsCartOpen(!isCartOpen);
    setIsHamburgerMenuOpen(false);
  };

  return (
    <button
      onClick={handleClick}
      className={`flex items-center justify-center gap-2 p-1.5 mb-1 transition duration-200 ${className}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-shopping-bag h-5 w-5"
      >
        <path d="M3 7h18v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V7z"></path>
        <path d="M8 7c0-2.2 1.8-4 4-4s4 1.8 4 4"></path>
        <path d="M8 7h8"></path>
      </svg>
      {hasLabel && <label>Cart</label>}
    </button>
  );
};

export default CartButton;
