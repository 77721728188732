import React, { useEffect, useState } from "react";
import { useSearch } from "../contexts/SearchContext";
import SearchInput from "./SearchInput";
import {
  filterGlobalTypeSearch,
  filterProducts,
  getLink,
  parameterToCategoryType,
  parameterToProductLineType,
} from "../extensions/helpers";
import { ProductCategory } from "../types/ProductCategory";
import { ProductLine } from "../types/ProductLine";
import ProductList from "./ProductList";
import { Product } from "../types/Product";
import SearchButton from "./SearchButton";
import { useNavigate } from "react-router-dom";
import { Products } from "../data/Products";
import { Theme } from "../types/Theme";
import MenuCloseButton from "./MenuCloseButton";

interface SearchMenuProps {}

const SearchMenu: React.FC<SearchMenuProps> = () => {
  const { searchQuery, setSearchQuery, isSearchMenuOpen, setIsSearchMenuOpen } =
    useSearch();
  const navigate = useNavigate();

  const [categorySearchFilter, setCategorySearchFilter] = useState<string[]>(
    []
  );
  const [productLineSearchFilter, setProductLineSearchFilter] = useState<
    string[]
  >([]);
  const [topProducts, setTopProducts] = useState<Product[]>([]);

  const handleClickCard = (
    category: ProductCategory,
    productLine?: ProductLine
  ) => {
    setIsSearchMenuOpen(false);
    navigate(
      getLink({
        category,
        productLine,
      }),
      {
        replace: true,
      }
    );
    setSearchQuery("");
    // window.location.reload();
  };

  useEffect(() => {
    if (searchQuery != null && searchQuery !== "") {
      setCategorySearchFilter(
        filterGlobalTypeSearch(searchQuery, ProductCategory)
      );
      setProductLineSearchFilter(
        filterGlobalTypeSearch(searchQuery, ProductLine)
      );
      setTopProducts(
        filterProducts(searchQuery, null, null, null, null, null, null, null, 3)
      );
    } else {
      setTopProducts(
        Products.filter((p) =>
          p.themes.map((t) => t.theme).includes(Theme.Classic)
        ).slice(0, 3)
      );
    }
  }, [searchQuery, setCategorySearchFilter, setProductLineSearchFilter]);

  return (
    <div
      className={`search-menu ${
        isSearchMenuOpen ? "translate-x-0" : "translate-x-full"
      }`}
    >
      <MenuCloseButton onClick={() => setIsSearchMenuOpen(!isSearchMenuOpen)} />

      <SearchInput />

      {searchQuery != null && searchQuery !== "" ? (
        categorySearchFilter.length <= 0 &&
        productLineSearchFilter.length <= 0 ? (
          <div className="flex flex-col gap-4 p-2">
            <h2>No Results Found</h2>
          </div>
        ) : (
          <div className="flex flex-col gap-4 p-2">
            {categorySearchFilter.length > 0 && (
              <div className="flex flex-col">
                <h2>Search Categories</h2>
                <div className="flex justify-between py-2">
                  {categorySearchFilter.map((c, i) => (
                    <SearchButton
                      key={i}
                      label={c}
                      hideIcon
                      onClick={() =>
                        handleClickCard(parameterToCategoryType(c))
                      }
                      className="border border-gray-950 hover:bg-blue-100"
                    />
                  ))}
                </div>
              </div>
            )}

            {productLineSearchFilter.length > 0 && (
              <div className="flex flex-col">
                <h2>Search Product Lines</h2>
                <div className="flex justify-between py-2">
                  {productLineSearchFilter.map((c, i) => (
                    <SearchButton
                      key={i}
                      label={c}
                      hideIcon
                      onClick={() =>
                        handleClickCard(
                          ProductCategory.Apparel,
                          parameterToProductLineType(c)
                        )
                      }
                      className="border border-gray-950 hover:bg-blue-100"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="flex flex-col gap-4 p-2">
          <div className="flex flex-col">
            <h2>Popular Lately</h2>
            <div className="flex justify-between py-2">
              <SearchButton
                label="Polos"
                onClick={() =>
                  handleClickCard(ProductCategory.Apparel, ProductLine.Polos)
                }
                className="border border-gray-950 hover:bg-blue-100"
              />
            </div>
          </div>
        </div>
      )}

      {topProducts.length > 0 && (
        <div className="flex flex-col p-2">
          <h2>Top Products</h2>
          <div className="flex justify-between">
            <ProductList
              products={topProducts}
              onClickProduct={() => setIsSearchMenuOpen(false)}
              isSmallVersion
              forceReload
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchMenu;
