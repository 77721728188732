import { Link } from "react-router-dom";
import { Products } from "../data/Products";
import { ProductCategory } from "../types/ProductCategory";
import { Theme } from "../types/Theme";
import ProductList from "./ProductList";
import { getLink } from "../extensions/helpers";

const ClassicProductList = () => {
  const classicProducts = Products.filter(
    (p) =>
      p.productCategories.includes(ProductCategory.Theme) &&
      p.themes.map((t) => t.theme).includes(Theme.Classic)
  );

  return (
    <div className="flex flex-col px-8 gap-6">
      <Link
        className="homepage-section-link"
        to={getLink({
          category: ProductCategory.Theme,
          theme: Theme.New,
        })}
      >
        New Arrivals
      </Link>
      <ProductList products={classicProducts} />
      <Link
        className="homepage-section-link self-center"
        to={getLink({
          category: ProductCategory.Apparel,
        })}
      >
        View All
      </Link>
    </div>
  );
};

export default ClassicProductList;
